import './styles/fonts.css'
import './styles/normalize.css'
import './styles/animation.css'
import './style.css'

import { nav, onScrollEvent, loadImages } from './js'

document.addEventListener('DOMContentLoaded', () => {
  const yearsExperience = document.getElementById('years-experience')
  yearsExperience.innerHTML = new Date().getFullYear() - 2006
  nav()
  onScrollEvent()
  loadImages()
})
