import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

const navEl = document.getElementById('nav-el')
const navAnchors = document.querySelectorAll('.nav ul > li > a')

// Nav Click events
export function nav() {
  document.getElementById('nav-open').addEventListener('click', function () {
    gsap.fromTo(navEl, { autoAlpha: 0 }, { autoAlpha: 1, display: 'flex', duration: 0.2 })
    gsap.fromTo(navAnchors, { autoAlpha: 0, y: 0, scale: 0.5 }, { scale: 1, autoAlpha: 1, stagger: 0.1, duration: 0.2, delay: 0.1 })
  })

  document.getElementById('nav-close').addEventListener('click', function () {
    gsap.to(navAnchors, { duration: 0.2, y: -100, opacity: 0, stagger: 0.1, onComplete: onCompleteAll })
  })

  navAnchors.forEach(function (node) {
    node.addEventListener('click', function (event) {
      event.preventDefault()
      gsap.to(window, { duration: 1, scrollTo: event.target.getAttribute('href') })
      onCompleteAll()
      toggleClass(event.target)
    })
  })
}

export function toggleClass(el) {
  if (window.matchMedia('(min-width: 1024px)').matches) {
    const active = document.querySelector('.nav ul > li > a.active')
    if (active) {
      active.classList.remove('active')
    }
    el.classList.add('active')
  }
}

function onCompleteAll() {
  if (!window.matchMedia('(min-width: 1024px)').matches) {
    gsap.to(navEl, { duration: 0.3, autoAlpha: 0, display: 'none' })
  }
}
