import { toggleClass } from './nav'
import { gsap } from 'gsap'
import { loadImages } from './gallery'

const sectionId = {
  services: 'services',
  work: 'work',
  contact: 'contact',
}
const pageState = {
  timer: null,
  services: null,
  work: null,
  loadGallery: null,
  loadServices: null,
  loadContact: null,
}

// Nav on scroll event
export function onScrollEvent() {
  document.onscroll = function () {
    if (!pageState.loadServices && document.getElementById(sectionId.services).getBoundingClientRect().top < 900) {
      loadImages('#services img[data-src]')
      pageState.loadServices = true
    }
    if (!pageState.loadGallery && document.getElementById(sectionId.work).getBoundingClientRect().top < 900) {
      loadImages('.gallery img[data-src]')
      pageState.loadGallery = true
    }
    if (!pageState.loadContact && document.getElementById(sectionId.contact).getBoundingClientRect().top < 900) {
      loadImages('#contact img[data-src]')
      pageState.loadContact = true
    }
    if (window.matchMedia('(min-width: 786px)').matches) {
      animateSection('services')
      animateSection('work')
    }
    if (window.matchMedia('(min-width: 1024px)').matches) {
      onScrollNavChangeLg()
    }
  }
}

function onScrollNavChangeLg() {
  if (pageState.timer) {
    window.clearTimeout(pageState.timer)
  }

  pageState.timer = window.setTimeout(function () {
    checkInViewSection()
  }, 25)
}

function checkInViewSection() {
  return [].forEach.call(document.querySelectorAll('main > section:not(.banner)'), function (el) {
    const topPosition = el.getBoundingClientRect().top
    if (topPosition > -50 && topPosition < 600) {
      const linkEl = document.querySelector('a[href="#' + el.getAttribute('id') + '"]')
      toggleClass(linkEl)
    }
  })
}

function animateSection(id) {
  if (!pageState[id]) {
    const el = document.getElementById(id)
    if (el.getBoundingClientRect().top < 650) {
      gsap.fromTo(
        document.querySelectorAll('#' + id + ' figure'),

        { opacity: 0, scale: 0.5 },
        { scale: 1, opacity: 1, delay: 0.1, duration: 0.25, stagger: 0.25 }
      )
      pageState[id] = true
      el.classList.add('active')
    }
  }
}
